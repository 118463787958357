<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    var ua = navigator.userAgent
    var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
      isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
      isAndroid = ua.match(/(Android)\s+([\d.]+)/),
      isMobile = isIphone || isAndroid
    if (!isMobile) {
      window.location.replace('https://www.zhuyu.vip')
    }
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>
