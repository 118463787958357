import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news.vue'),
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('@/views/newsDetail.vue'),
  },
  {
    path: '/intercat',
    name: 'intercat',
    component: () => import('@/views/intercat.vue'),
  },
  {
    path: '/agree',
    name: 'agree',
    component: () => import('@/views/agree.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy.vue'),
  },
  {
    path: '/privacyThree',
    name: 'privacyThree',
    component: () => import('@/views/privacyThree.vue'),
  },
]
export const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})
