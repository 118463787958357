import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import './assets/css/reset.scss'
import { Pagination, Icon } from 'vant'
import 'vant/lib/index.css'
router.afterEach(() => {
  window.scrollTo(0, 0)
})
const app = createApp(App)
app.use(router)
app.use(Pagination)
app.use(Icon)
app.mount('#app')
